<script setup lang="ts">
import UserInputText from 'theme/components/molecules/UserInputText.vue';
import NuxtLinkFlat from '~theme/components/atoms/NuxtLinkFlat.vue';
import PillShapedSpan from '~theme/components/atoms/PillShapedSpan.vue';

const props = defineProps<{
  variant: 'simple' | 'complicated';
  id: InterviewId;
}>();
const interview = useInterviewSummary(() => props.id);
const interviewee = computed(() => interview.value.interviewee);

const intervieweeSummary = computed(
  () =>
    `${interviewee.value.gender} (${interviewee.value.age}歳) / 家族構成 : ${interviewee.value.household}`
);
</script>

<template>
  <div v-if="variant === 'simple'" :class="$style.simpleCard">
    <div :class="$style.simpleCard__title">{{ interview.title }}</div>
    <div :class="$style.simpleCard__summary">{{ intervieweeSummary }}</div>
    <NuxtLinkFlat :class="$style.simpleCard__button" :href="`/interview/${id}`"
      >お悩み解決<wbr />フローをみる</NuxtLinkFlat
    >
  </div>
  <div v-else :class="$style.complicatedCard">
    <div :class="$style.complicatedCard__profile">
      <img
        :class="$style.complicatedCard__avatar"
        :src="interviewee.avatarUrl"
      />
      <div :class="$style.complicatedCard__title">{{ interview.title }}</div>
      <div :class="$style.complicatedCard__summary">
        {{ intervieweeSummary }}
      </div>
    </div>
    <div>
      <div :class="$style.complicatedCard__subheading">相談のきっかけ</div>
      <UserInputText
        :class="$style.complicatedCard__text"
        :text="interview.motivation"
      />
    </div>
    <div>
      <div :class="$style.complicatedCard__subheading">体験してみての感想</div>
      <UserInputText
        :class="$style.complicatedCard__text"
        :text="interview.impression"
      />
    </div>
    <div :class="$style.complicatedCard__spacer" />
    <div :class="$style.complicatedCard__categories">
      <PillShapedSpan
        v-for="c in interview.categories"
        :key="c"
        :class="$style.complicatedCard__category"
        >{{ c }}</PillShapedSpan
      >
    </div>
    <NuxtLinkFlat :href="`/interview/${id}`"
      >お悩み解決フローをみる</NuxtLinkFlat
    >
  </div>
</template>

<style module lang="scss">
.simpleCard {
  background-color: $color-surface-primary;
  box-shadow: $shadow-8;
  border-radius: 8px;
  padding: 24px;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include mobile-only {
    min-height: unset;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.8;
  }

  &__summary {
    font-size: 12px;
    line-height: 1.8;
    flex: 1;
    margin-bottom: 16px;
    @include mobile-only {
      margin-bottom: 8px;
    }
  }

  &__button {
    word-break: keep-all;
    text-align: center;
    @include tablet-only {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.complicatedCard {
  background-color: $color-surface-primary;
  box-shadow: $shadow-8;
  border-radius: 8px;
  padding: 24px;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;

  @include mobile-only {
    gap: 12px;
  }

  &__profile {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 16px;
    row-gap: 8px;
  }

  &__avatar {
    width: 72px;
    height: 72px;
    grid-row: 1 / 3;
    align-self: center;
  }

  &__title {
    line-height: 1.8;
    font-size: 20px;
    font-weight: bold;
    @include mobile-only {
      font-size: 18px;
    }
  }

  &__summary {
    line-height: 1.8;
    font-size: 12px;
  }

  &__subheading {
    line-height: 1.8;
    font-size: 16px;
    font-weight: bold;
  }

  &__text {
    margin-top: 4px;
    line-height: 1.8;
  }

  &__category {
    background-color: $color-surface-secondary;
    border: 2px solid $color-border-low-emphasis;
    padding: 4px 8px;
  }

  &__categories {
    display: flex;
    gap: 4px;
  }

  &__spacer {
    margin-top: -16px;
    flex: 1 0 0;
  }
}
</style>
